<template>
  <div>
    <div class="filter-body">
      <div class="filter-sidebar white">
        <p class="filter-heading fs-16 fw-700 dark-font">Filter</p>
        <div class="sidebar-content">
          <div class="filter-item filter-chapter" v-if="!hideFirstFilter">
            <div @click="isChapterFilterShow = !isChapterFilterShow" class="chapter-title pointer">
              <p class="fs-13 fw-700 dark-font filter-item-heading">
                {{ firstFilterHeading }}
                <i class="material-icons">{{
                  isChapterFilterShow ? "expand_less" : "expand_more"
                }}</i>
              </p>
            </div>
            <div v-if="isChapterFilterShow" class="filter-list">
              <div
                class="filter-list-item flex-column items-baseline"
                v-if="chapterListData.length > 0"
              >
                <div
                  class="flex items-center"
                  v-for="(chapData, index) in chapterListData"
                  :key="index"
                >
                  <input
                    type="radio"
                    :id="chapData.ChapterName"
                    :value="chapData.ChapterName"
                    @change="getChapId($event)"
                    name="chapter"
                  />
                  <label :for="chapData.ChapterName" class="pointer diff-label">{{
                    chapData.ChapterName
                  }}</label>
                </div>
              </div>
              <div class="filter-list-item" style="margin-top: -10px">
                <input
                  type="radio"
                  checked
                  id="organic-chemistry"
                  value="all"
                  @change="getChapId($event)"
                  name="chapter"
                />
                <label for="organic-chemistry" class="pointer">All</label>
              </div>
            </div>
          </div>
          <div class="filter-item filter-concept" v-if="!hideSecondFilter">
            <div @click="isConceptFilterShow = !isConceptFilterShow" class="concept-title pointer">
              <p class="fs-13 fw-700 dark-font filter-item-heading">
                {{ secondFilterHeading }}
                <i class="material-icons">{{
                  isConceptFilterShow ? "expand_less" : "expand_more"
                }}</i>
              </p>
            </div>
            <div v-if="isConceptFilterShow" class="filter-list">
              <div class="filter-list-item">
                <input
                  type="radio"
                  id="organic-chemistry"
                  value="organic chemistry"
                  name="concept"
                />
                <label for="organic-chemistry" class="pointer">Organic Chemistry</label>
              </div>
              <div class="filter-list-item">
                <input
                  type="radio"
                  checked
                  id="organic-chemistry"
                  value="organic chemistry"
                  name="concept"
                />
                <label for="organic-chemistry" class="pointer">All</label>
              </div>
            </div>
          </div>
          <div class="filter-item filter-difficulty" v-if="!hideThirdFilter">
            <div
              @click="isDifficultyFilterShow = !isDifficultyFilterShow"
              class="difficulty-title pointer"
            >
              <p class="fs-13 fw-700 dark-font filter-item-heading">
                {{ thirdFilterHeading }}
                <i class="material-icons">{{
                  isDifficultyFilterShow ? "expand_less" : "expand_more"
                }}</i>
              </p>
            </div>
            <div v-if="isDifficultyFilterShow" class="filter-list">
              <div
                class="filter-list-item flex-column items-baseline"
                v-if="difficultyData.length > 0"
              >
                <div
                  v-for="(diffData, index) in difficultyData"
                  :key="index"
                  class="flex items-center input-filter"
                >
                  <input
                    type="checkbox"
                    :id="diffData.DifficultyGroup"
                    :value="diffData.DifficultyGroup"
                    v-model="selectedDiffGrpData"
                    @change="getDiff($event)"
                    name="difficulty"
                  />
                  <label
                    :for="diffData.DifficultyGroup"
                    class="pointer diff-label capitalize flex items-center"
                    >{{ diffData.DifficultyGroup }} <span class="block diff-indicator"></span
                  ></label>
                </div>
              </div>
              <div class="filter-list-item" style="margin-top: -10px">
                <input
                  type="checkbox"
                  id="all"
                  value="all"
                  @change="getAllDiff($event)"
                  name="difficultyAll"
                />
                <label for="all" class="pointer">All</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChapterFilterShow: false,
      isConceptFilterShow: false,
      isDifficultyFilterShow: false,
      selectedDiffGrpData: [],
      selectedAllDiff: [],
      isSingleLevelSelected: true,
      singleDiffLevel: null,
    };
  },
  props: {
    firstFilterHeading: {
      type: String,
      default: "Subject",
    },
    secondFilterHeading: {
      type: String,
      default: "Chapter",
    },
    thirdFilterHeading: {
      type: String,
      default: "Difficulty Level",
    },
    difficultyData: Array,
    chapterListData: Array,
    hideFirstFilter: Boolean,
    hideSecondFilter: Boolean,
    hideThirdFilter: Boolean,
  },
  methods: {
    getChapId(event) {
      this.$emit("selectedChapter", event.target.value);
    },
    getDiff(event) {
      this.$emit("selectedDiffGrp", this.selectedDiffGrpData);
      this.singleDiffLevel = event.target.value;
    },
    getAllDiff(event) {
      this.$emit("selectedDiffGrp", event.target.value);
    },
  },
  updated() {
    this.$emit("selectedDiffGrp", this.selectedDiffGrpData);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap");

* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

input {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: visible !important;
}

.material-icons {
  font-size: 16px;
}

.dark-font {
  color: #333333;
}

.light-font {
  color: #676767;
}

.purple-font {
  color: #693383;
}

.blue-font {
  color: #3751ff;
}

.green-font {
  color: #16a085;
}

.red-font {
  color: #ea7b7b;
}

.sky-blue-font {
  color: #6eb7fd;
}

.light-purple-font {
  color: #a36efd;
}

.dark-gray-font {
  color: #8e8e8e;
}

.black-font {
  color: #000;
}

.green-bg {
  background-color: #16a085;
}

.red-bg {
  background-color: #ea7b7b;
}

.PS-screen {
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
}

.PS-title-box {
  margin-top: 20px;
  margin-bottom: 5px;
}

.PS-screen-inner {
  width: 80%;
  margin: 0 auto;
}

.PS-top {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 20px;
}

/* .PS-top > .summary-box{
      flex: 1 1 50%;
      min-width: 50%;
  } */
/* .PS-top > .summary-box{
      flex: 1 1 50%;
      min-width: 50%;
  } */

.base-box {
  background-color: #fff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 20px;
  height: 420px;
}

.summary-box {
  width: 50%;
  flex: 1 1 540px;
  min-width: 540px;
}

.highlights-box {
  width: 30%;
  flex: 1 1 360px;
  min-width: 360px;
}

.subject-box {
  width: 100%;
  flex: 1 1 234px;
  min-width: 234px;
}

.bullet {
  border-radius: 50px;
  width: 15px;
  height: 15px;
  display: block;
}

.skipped-bullet {
  background: #e8e8e8;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.3);
}

.correct-bullet {
  background: #00b894;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 184, 148, 0.3);
}

.incorrect-bullet {
  background: #ff7675;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(255, 118, 117, 0.3);
}

.revision-bullet {
  background: #a36efd;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(163, 110, 253, 0.3);
}

.clarification-bullet {
  background: #6eb7fd;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 6px rgba(110, 183, 253, 0.3);
}

.SC-left {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.SC-left p {
  display: flex;
  align-items: center;
  gap: 8px;
}

.summary-content {
  display: flex;
  justify-content: space-between;
}

.filter-sidebar {
  width: 100%;
  height: 100%;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
}

.filter-item {
  border-top: 1px solid #e0e4f0;
  padding: 20px 15px;
}

.filter-heading {
  padding: 15px;
}

.filter-item-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-list {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-list label {
  font-weight: 400;
  font-size: 13px;
  color: #000;
}
.diff-indicator {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-left: 8px;
}
.diff-label {
  margin-left: 9px;
}
.input-filter:nth-child(1) .diff-indicator {
  background: #16a085;
}
.input-filter:nth-child(2) .diff-indicator {
  background: #edbf6b;
}
.input-filter:nth-child(3) .diff-indicator {
  background: #ea7b7b;
}
</style>
