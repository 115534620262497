<template>
  <div>
    <!-- <div class="flex flex-between"> -->
      <FilterComponent :firstFilterHeading="'Chapter'" :hideFirstFilter="false" :secondFilterHeading="'Subject'" :hideSecondFilter="true" :thirdFilterHeading="'Difficulty Level'" :hideThirdFilter="false" :difficultyData="diffiLevelData" :chapterListData="chapterListData" @selectedChapter="getChapterData($event)" @selectedDiffGrp="getDiffGrp($event)" @selectedAllDiffGrp="getAllDiffGrp($event)" />
    <!-- </div> -->
    <h2>{{chapterId}}</h2>
    <h2 v-if="diffGrp.length > 0">{{diffGrp}}</h2>
    <!-- <h2  v-if="diffGrp.length > 0 || diffGrpAll.length > 0">{{diffGrpAll.length > 0 ? diffGrpAll : diffGrp}}</h2> -->
  </div>
</template>

<script>
import FilterComponent from "../components/FilterComponent.vue";

export default {
  data() {
    return {
      QuestionData: [],
      diffiLevelData: [],
      chapterListData: [],
      chapterId: null,
      diffGrpAll: [],
      diffGrp: [],
    };
  },
  activated() {
    //  this.QuestionData = manualQuestion.data.questions[0].questions;
    this.diffiLevelData = [...new Set(this.QuestionData.map((item) => ({ DifficultyGroup: item.DifficultyGroup.toLowerCase() })))];
    this.diffiLevelData = [...new Map(this.diffiLevelData.map((item) => [item.DifficultyGroup, item])).values()].reverse();

  //   this.chapterListData = [...new Set(this.QuestionData.map((item) => ({ CourseChapterId: item.CourseChapterId })))];
  //   this.chapterListData = [...new Map(this.chapterListData.map((item) => [item.CourseChapterId, item])).values()];
  //   console.log("this.diffiLevelData: ", this.diffiLevelData);
  //   console.log("this.chapterListData: ", this.chapterListData);
  //   // this.diffGrpAll = this.diffiLevelData.map((item) => item.DifficultyGroup);
  //   // console.log("this.diffGrpAll: ", this.diffGrpAll);
  },
  components: {
    FilterComponent,
  },
  methods: {
    getChapterData(chapData) {
      this.chapterId = chapData;
    },
    getDiffGrp(diffData) {
      console.log("diffGrp CHILD: ", this.diffGrp);
      console.log("diffData: ", diffData);
      if (diffData.includes("all")) {
        this.diffGrp = this.diffiLevelData.map((item) => item.DifficultyGroup);
      } else {
        this.diffGrp = diffData;
      }
      // if (this.diffGrp.length > 0) {
      //   this.diffGrpAll = [];
      // }
    },
    // getAllDiffGrp(allDiffGrp) {
    //   console.log("allDiffGrp: ", allDiffGrp);
    //   if (allDiffGrp === "all") {
    //     this.diffGrpAll = this.diffiLevelData.map((item) => item.DifficultyGroup);
    //     console.log("allDiffGrp this.diffGrpAll: ", this.diffGrpAll);
    //   }
    // },
  },
};
</script>

<style  scoped>

</style>
